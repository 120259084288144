// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Pacifico&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;600;700;900&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:wght@300;400;500;600;700;800;900;&display=swap);"]);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@font-face{font-family:'Franchise';src:url("/fonts/franchise.ttf") format("truetype")}@font-face{font-family:'hypism';src:url("/fonts/hypism.ttf") format("truetype")}.robotoflex{font-family:"Roboto Flex", sans-serif}.franchise{font-family:'Franchise' !important}.fjalla{font-family:'Fjalla One', sans-serif !important}.yanone{font-family:'Yanone Kaffeesatz', sans-serif !important}.opensans{font-family:'Open Sans', sans-serif}.oswald{font-family:'Oswald', sans-serif}.sourcepro{font-family:'Source Sans Pro', sans-serif}
`, "",{"version":3,"sources":["webpack://./src/scss/fonts.scss"],"names":[],"mappings":"AAYA,WACI,uBAAuB,CACvB,kDAAkD,CAEtD,WACI,oBAAoB,CACpB,+CAA+C,CAInD,YAAc,qCAAsC,CAAI,WAC1C,kCAAmC,CAAI,QACvC,+CAAgD,CAAI,QACpD,sDAAuD,CAAI,UAC3D,mCAAoC,CAAI,QACxC,gCAAiC,CAAI,WACrC,yCAA0C","sourcesContent":["/* GOOGLE FONTS */\n@import url('https://fonts.googleapis.com/css2?family=Yanone+Kaffeesatz');\n@import url('https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Oswald:wght@400;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;300;400;500;700&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Pacifico&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Source+Sans+Pro&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Fjalla+One&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Lato:wght@100;300;400;500;600;700;900&display=swap');\n@import url('https://fonts.googleapis.com/css?family=Poppins:wght@300;400;500;600;700;800;900;&display=swap');\n@import url('https://fonts.googleapis.com/css2?family=Roboto+Flex:opsz,wght@8..144,100..1000&display=swap');\n\n@font-face {\n    font-family:'Franchise';\n    src:url('/fonts/franchise.ttf') format(\"truetype\");\n}\n@font-face {\n    font-family:'hypism';\n    src:url('/fonts/hypism.ttf') format(\"truetype\");\n}\n\n\n.robotoflex { font-family: \"Roboto Flex\", sans-serif; }\n.franchise  { font-family: 'Franchise' !important; }\n.fjalla     { font-family: 'Fjalla One', sans-serif !important; }\n.yanone     { font-family: 'Yanone Kaffeesatz', sans-serif !important; }\n.opensans   { font-family: 'Open Sans', sans-serif; }\n.oswald     { font-family: 'Oswald', sans-serif; }\n.sourcepro  { font-family: 'Source Sans Pro', sans-serif; }"],"sourceRoot":""}]);
// Exports
export default ___CSS_LOADER_EXPORT___;
